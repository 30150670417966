<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Add Timesheet</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mt-1">
          <b-col
            v-if="types.length > 1"
            cols="6"
          >
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  v-model="type"
                  :options="types"
                  :reduce="item => item"
                  :clearable="false"
                  placeholder="Select"
                  @input="onTypeChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isEmployee"
            cols="6"
          >
            <b-form-group
              label="Consultant"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Consultant"
                rules="required"
              >
                <v-select
                  v-model="employee"
                  :options="employees"
                  :reduce="item => item"
                  :clearable="false"
                  placeholder="Select"
                  @input="onEmployeeChanged()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Timesheet Frequency"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="timesheet_frequency"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Month"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Month"
                rules="required"
              >
                <flat-pickr
                  v-model="month"
                  class="form-control"
                  :config="$store.state.maxMonthConfig"
                  placeholder="MMM-yyyy"
                  @on-change="monthChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-show="isShowWeeks"
            cols="6"
          >
            <b-form-group
              label="Select Week"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Week"
                :rules="isShowWeeks ? 'required' : ''"
              >
                <v-select
                  v-model="week_id"
                  :options="showWeeks"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                  @input="getSaysBasedOnWeek($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="isShowDays && isShowWeeks">
          <b-col cols="12">
            <dl class="row">
              <dt class="col-sm-2 text-right mt-50">
                Day's
              </dt>
              <dd class="col-sm-10">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered table-custom-pd mb-0">
                    <thead>
                      <tr>
                        <th>Sun {{ sun_day }}</th>
                        <th>Mon {{ mon_day }}</th>
                        <th>Tue {{ tue_day }}</th>
                        <th>Wed {{ wed_day }}</th>
                        <th>Thu {{ thu_day }}</th>
                        <th>Fri {{ fri_day }}</th>
                        <th>Sat {{ sat_day }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="addTimesheet.sunday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="addTimesheet.monday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="addTimesheet.tueday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="addTimesheet.wedday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="addTimesheet.thusday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="addTimesheet.friday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="addTimesheet.satday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </dd>
            </dl>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Total Hours"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Total Hours"
                rules="required"
              >
                <b-form-input
                  v-model="addTimesheet.total_hours"
                  :disabled="!isShowWeeks ? disabled: ''"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-show="User.profile().allow_timesheet_parents[0].is_expenses_exist"
            cols="6"
          >
            <b-form-group
              label="Expenses"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Expenses"
                rules="required"
              >
                <b-input-group
                  prepend="$"
                >
                  <money
                    v-model="addTimesheet.expenses"
                    :min="0"
                    :step="0.5"
                    v-bind="money"
                    class="form-control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Documents
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <v-select
                v-model="documentNameId"
                :clearable="true"
                placeholder="Select"
                :options="documentNames"
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group
              label="Attachment"
              label-cols-md="4"
              class="text-right"
            >
              <div class="d-flex">
                <input
                  ref="myFileInput"
                  type="file"
                  placeholder="Choose a file or drop it here..."
                  class="form-control text-left"
                  @change="attahamentFileSelected"
                >
                <b-button
                  variant="outline-primary"
                  class="ml-1 px-1"
                  @click="addFile"
                >
                  Upload
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-button-group
              v-for="(option, index) in addTimesheet.documents"
              :key="option.id"
              :value="option.id"
              class="mr-1"
            >
              <b-badge
                :href="option.attachment_link"
                :title="option.name"
                target="option.attachment_link"
                variant="light-primary"
                class="rounded-right-0 line-height-inherit"
              >
                <span>{{ option.name }}</span>
              </b-badge>
              <b-button
                variant="danger"
                class="px-50 py-25"
                @click="removeFile(option, index)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Add Timesheet
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButtonGroup, BInputGroup, BButton, BBadge, BFormSpinbutton,
} from 'bootstrap-vue'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import User from '@/js/user'
import Timesheet from '@/js/timesheets'
import LIST from '@/js/lists'
import moment from 'moment'
import Constants from '@/js/constant'
import auth from '@/auth/authService'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    vSelect,
    BButton,
    BButtonGroup,
    BBadge,
    BFormSpinbutton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      User,
      addTimesheet: {
        timesheet_frequency_id: null,
        total_hours: '',
        expenses: '',
        sunday: {
          date: null,
          hours: 0.0,
        },
        monday: {
          date: null,
          hours: 0,
        },
        tueday: {
          date: null,
          hours: 0,
        },
        wedday: {
          date: null,
          hours: 0,
        },
        thusday: {
          date: null,
          hours: 0,
        },
        friday: {
          date: null,
          hours: 0,
        },
        satday: {
          date: null,
          hours: 0,
        },
        parent_id: null,
        parent_type_id: null,
        user_id: null,
        monthDate: null,
        week_start_date: null,
        week_end_date: null,
        documents: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      type: null,
      employee: null,
      month: null,
      sun_day: null,
      mon_day: null,
      tue_day: null,
      wed_day: null,
      thu_day: null,
      fri_day: null,
      sat_day: null,
      week_id: null,
      endDay: null,
      firstDay: null,
      isShowWeeks: false,
      isShowMonths: false,
      isShowDays: false,
      isEmployee: false,
      selectedFile: null,
      documentNameId: null,
      employees: [],
      types: [],
      frequencies: [],
      timesheet_frequency: '',
      weekStarts: 0,
      weeks: [],
      status: [],
      showWeeks: [],
      documentNames: [],
    }
  },
  mounted() {
  },
  created() {
    if (!User.profile().allow_timesheet_parents.length > 0) {
      if (User.profile().job_exist) {
        window.location.hash = '#/jobs'
      } else if (User.profile().invoice_exist) {
        window.location.hash = '#/invoices'
      } else {
        auth.logout()
      }
      return
    }
    this.types = User.profile().allow_timesheet_parents
    if (User.profile().allow_timesheet_parents.length === 1) {
      if (User.profile().allow_timesheet_parents[0].parent_type === 'Employee') {
        this.addTimesheet.timesheet_frequency_id = User.profile().allow_timesheet_parents[0].frequency_type_id
        this.addTimesheet.user_id = User.profile().allow_timesheet_parents[0].parent_id
        this.addTimesheet.parent_id = User.profile().allow_timesheet_parents[0].parent_id
        this.addTimesheet.parent_type_id = User.profile().allow_timesheet_parents[0].parent_type_id
        this.timesheet_frequency = User.profile().allow_timesheet_parents[0].frequency_type
        this.isEmployee = true
      } else if (User.profile().allow_timesheet_parents[0].parent_type === 'Employer') {
        this.addTimesheet.parent_type_id = User.profile().allow_timesheet_parents[0].parent_type_id
        this.addTimesheet.parent_id = User.profile().allow_timesheet_parents[0].parent_id
        LIST.getEmployerTimeSheetEmployeeConsultantFullList(this, User.profile().allow_timesheet_parents[0].parent_id)
      } else if (User.profile().allow_timesheet_parents[0].parent_type === 'Suppliers') {
        this.addTimesheet.parent_type_id = User.profile().allow_timesheet_parents[0].parent_type_id
        this.addTimesheet.parent_id = User.profile().allow_timesheet_parents[0].parent_id
        LIST.getSupplierTimeSheetEmployeeConsultantFullList(this, User.profile().allow_timesheet_parents[0].parent_id)
      } else if (User.profile().allow_timesheet_parents[0].parent_type === 'Referrer') {
        this.addTimesheet.parent_type_id = User.profile().allow_timesheet_parents[0].parent_type_id
        this.addTimesheet.parent_id = User.profile().allow_timesheet_parents[0].parent_id
        LIST.getReferrerTimeSheetEmployeeConsultantFullList(this, User.profile().allow_timesheet_parents[0].parent_id)
      } else if (User.profile().allow_timesheet_parents[0].parent_type === 'SubVendor') {
        this.addTimesheet.parent_type_id = User.profile().allow_timesheet_parents[0].parent_type_id
        this.addTimesheet.parent_id = User.profile().allow_timesheet_parents[0].parent_id
        LIST.getSubvendorTimeSheetEmployeeConsultantFullList(this, User.profile().allow_timesheet_parents[0].parent_id)
      }
    }
    this.month = moment().format('MMM-YYYY')
    if (this.timesheet_frequency != null) {
      if (this.timesheet_frequency.toLowerCase() === 'weekly') {
        this.getWeekCount(this.month)
        this.isShowWeeks = true
        this.isShowMonths = false
      } else {
        const monthDate = this.month.split('-')
        this.addTimesheet.monthDate = Constants.getEprmMonthDateFromString(`${moment(monthDate[0], 'MMMM').format('MM')}-01-${monthDate[1]}`)
      }
    }
    LIST.getDocumentNameFullList(this, 'FA', 'TS')
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addTimesheet.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          let documentExist = false
          this.addTimesheet.documents.forEach(elem => {
            const items = this.documentNames.filter(data => data.label.toLowerCase() === 'approved timesheet' && data.id === elem.document_name_id)
            if (items.length !== 0) {
              documentExist = true
            }
          })
          if (documentExist) {
            this.submitted = true
            const monthDate = this.month.split('-')
            this.addTimesheet.month_date = Constants.getEprmMonthDateFromString(`${moment(monthDate[0], 'MMMM').format('MM')}-01-${monthDate[1]}`)
            Timesheet.addTimesheet(this, this.addTimesheet, false)
          } else {
            this.$swal({
              title: 'Please upload approved timesheet',
            })
          }
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(obj, position) {
      const dis = this
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dis.addTimesheet.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addTimesheet.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    onTypeChanged() {
      this.employees = []
      this.addTimesheet.timesheet_frequency_id = null
      this.addTimesheet.user_id = null
      this.employee = null
      this.addTimesheet.parent_id = this.type.parent_id
      this.addTimesheet.parent_type_id = this.type.parent_type_id
      if (this.type.parent_type.toLowerCase() === 'employee') {
        this.addTimesheet.user_id = this.type.parent_id
      } else if (this.type.parent_type.toLowerCase() === 'employer') {
        LIST.getEmployerTimeSheetEmployeeConsultantFullList(this, this.type.parent_id)
      } else if (this.type.parent_type === 'Suppliers') {
        LIST.getSupplierTimeSheetEmployeeConsultantFullList(this, this.type.parent_id)
      } else if (this.type.parent_type === 'Referrer') {
        LIST.getReferrerTimeSheetEmployeeConsultantFullList(this, this.type.parent_id)
      } else if (this.type.parent_type === 'SubVendor') {
        LIST.getSubvendorTimeSheetEmployeeConsultantFullList(this, this.type.parent_id)
      }
    },
    onEmployeeChanged() {
      if (this.employee != null) {
        this.addTimesheet.timesheet_frequency_id = this.employee.frequency_id
        this.addTimesheet.user_id = this.employee.id
        this.timesheet_frequency = this.employee.frequency
        if (this.employee.frequency.toLowerCase() === 'weekly') {
          this.getWeekCount(this.month)
          this.isShowWeeks = true
          this.isShowMonths = false
        } else {
          this.isShowWeeks = false
          this.isShowMonths = true
          const monthDate = this.month.split('-')
          this.addTimesheet.monthDate = Constants.getEprmMonthDateFromString(`${moment(monthDate[0], 'MMMM').format('MM')}-01-${monthDate[1]}`)
        }
      }
    },
    minMonth() {
      return moment().format('YYYY-MMM-DD')
    },
    nextDate() {
      return moment(moment().add(1, 'days')).format('MM-DD-YYYY')
    },
    previousDate() {
      return moment(moment().add(-1, 'days')).format('MM-DD-YYYY')
    },
    hoursChanged() {
      let totlaHours = 0
      if (this.addTimesheet.sunday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.sunday.hours)
      }
      if (this.addTimesheet.monday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.monday.hours)
      }
      if (this.addTimesheet.tueday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.tueday.hours)
      }
      if (this.addTimesheet.wedday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.wedday.hours)
      }
      if (this.addTimesheet.thusday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.thusday.hours)
      }
      if (this.addTimesheet.friday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.friday.hours)
      }
      if (this.addTimesheet.satday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.addTimesheet.satday.hours)
      }
      this.addTimesheet.total_hours = totlaHours
    },
    getFirstDay() {
      if (this.firstWeekDay != null) {
        return moment(this.firstWeekDay).format('MM/DD')
      }
      return ''
    },
    onFrequencyChange(event) {
      const i = this.frequencies.find(item => item.id === event.target.value)
      if (i.name.toLowerCase() === 'monthly') {
        this.isShowWeeks = false
        this.isShowMonths = true
        this.addTimesheet.monthDate = Constants.getEprmMonthDateFromString(`${moment(this.month, 'MMM-YYYY').format('MM')}-01-${moment(this.month, 'MMM-YYYY').format('YYYY')}`)
      } else {
        this.getWeekCount(this.addTimesheet.month)
        this.isShowWeeks = true
        this.isShowMonths = false
      }
    },
    getWeekCount() {
      this.weeks = []
      let startDate = this.month
      startDate = moment(startDate, 'MMM-YYYY').format('YYYY-MM')
      this.firstDay = moment(startDate).startOf('month').day(0)
      this.endDay = moment(startDate).endOf('month').day(0)
      if (this.endDay.format('dddd') === 'Sunday') { this.endDay = this.endDay.day(6) }
      if (this.endDay.format('dddd') === 'Monday') { this.endDay = this.endDay.day(5) }
      if (this.endDay.format('dddd') === 'Tuesday') { this.endDay = this.endDay.day(4) }
      if (this.endDay.format('dddd') === 'Wednesday') { this.endDay = this.endDay.day(3) }
      if (this.endDay.format('dddd') === 'Thursday') { this.endDay = this.endDay.day(2) }
      if (this.endDay.format('dddd') === 'Friday') { this.endDay = this.endDay.day(1) }
      const monthRange = moment.range(this.firstDay, this.endDay)
      const array = Array.from(monthRange.by('days'))
      array.forEach(mday => {
        if (this.weeks.indexOf(mday.week()) === -1) {
          this.weeks.push(mday.week())
        }
      })
      this.weekStarts = this.weeks[0] - 1
      this.showWeeks = []
      array.forEach(mday => {
        if (moment(this.firstDay).isAfter(moment(this.firstDay).week((mday.week())).day(6), 'day')) {
          if (this.showWeeks.find(item => item.label === `${moment(this.firstDay).week((mday.week())).add('years', 1).day(0)
            .format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).add('years', 1).day(6)
            .format('MM-DD-YYYY')}`) === null) {
            this.showWeeks.push({
              id: mday.week() - this.weekStarts,
              label: `${moment(this.firstDay).week((mday.week())).add('years', 1).day(0)
                .format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).add('years', 1).day(6)
                .format('MM-DD-YYYY')}`,
            })
          }
        } else if (!this.showWeeks.find(item => item.label === `${moment(this.firstDay).week((mday.week())).day(0).format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).day(6).format('MM-DD-YYYY')}`)) {
          this.showWeeks.push({
            id: mday.week() - this.weekStarts,
            label: `${moment(this.firstDay).week((mday.week())).day(0)
              .format('MM-DD-YYYY')} - ${moment(this.firstDay).week((mday.week())).day(6)
              .format('MM-DD-YYYY')}`,
          })
        }
      })
    },
    getSaysBasedOnWeek(event) {
      this.isShowDays = true
      let firstWeekDay = moment(this.firstDay).week(Number(event) + this.weekStarts).day(0)
      if (firstWeekDay.isBefore(this.firstDay)) {
        firstWeekDay = this.firstDay
      }
      this.addTimesheet.week_start_date = Constants.getEprmMonthDateFromString(moment(firstWeekDay))
      this.addTimesheet.week_end_date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(6, 'days'))
      this.sun_day = moment(firstWeekDay).format('MM/DD')
      this.mon_day = moment(firstWeekDay).add(1, 'days').format('MM/DD')
      this.tue_day = moment(firstWeekDay).add(2, 'days').format('MM/DD')
      this.wed_day = moment(firstWeekDay).add(3, 'days').format('MM/DD')
      this.thu_day = moment(firstWeekDay).add(4, 'days').format('MM/DD')
      this.fri_day = moment(firstWeekDay).add(5, 'days').format('MM/DD')
      this.sat_day = moment(firstWeekDay).add(6, 'days').format('MM/DD')
      this.addTimesheet.sunday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay))
      this.addTimesheet.monday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(1, 'days'))
      this.addTimesheet.tueday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(2, 'days'))
      this.addTimesheet.wedday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(3, 'days'))
      this.addTimesheet.thusday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(4, 'days'))
      this.addTimesheet.friday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(5, 'days'))
      this.addTimesheet.satday.date = Constants.getEprmMonthDateFromString(moment(firstWeekDay).add(6, 'days'))
    },
    monthChanged(selectedDate, dateStr) {
      this.isShowDays = false
      this.week_id = null
      this.month = dateStr
      this.addTimesheet.monthDate = Constants.getEprmMonthDateFromString(`${moment(dateStr, 'MMM-YYYY').format('MM')}-01-${moment(dateStr, 'MMM-YYYY').format('YYYY')}`)
      if (this.isShowWeeks) {
        this.getWeekCount(dateStr)
        this.isShowWeeks = true
        this.isShowMonths = false
      }
    },
    setStatus(status) {
      this.status = status
    },
    setEmployees(employees) {
      this.employees = employees
    },
    setTimeSheetFrequencies(frequencies) {
      this.frequencies = frequencies
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    timesheetAdded(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    timesheetNotAdded(data) {
      this.$refs.showLoading = false
      if (data.return_code === 4) {
        this.$swal({
          title: 'Warning!',
          text: data.return_message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, create it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.showLoading = true
            Timesheet.addTimesheet(this, this.addTimesheet, true)
          }
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: data.return_message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    back() {
      this.$router.push('/timesheets').catch(() => {})
    },
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
